import { useStore } from 'app-engine/store'
import { scrollbar_css } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { useSigningRequest } from 'hooks/use-signing-request'
import {
  Html5Qrcode,
  Html5QrcodeSupportedFormats,
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from 'html5-qrcode'
import { Html5QrcodeConfigs, Html5QrcodeFullConfig } from 'html5-qrcode/esm/html5-qrcode'
import { useEffect, useRef } from 'react'

export function QrScan({ onSuccess, onError, ...config }: QrScanProps) {
  const account = useStore.useAccount()
  const pubKey = useStore.usePub_key()
  const [{ actions }, { requestSignature }] = useSigningRequest()
  const html5QrcodeScannerRef = useRef<Html5Qrcode | null>(null)

  const cleanQRScanner = () => {
    const html5QrcodeScanner = html5QrcodeScannerRef.current

    try {
      html5QrcodeScanner?.pause()
      html5QrcodeScanner?.stop()
      html5QrcodeScanner?.clear()
    } catch (error) {
      console.error('Failed to stop html5QrcodeScanner. ', error)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!html5QrcodeScannerRef.current) {
        html5QrcodeScannerRef.current = new Html5Qrcode('qrcode-scanner', {
          ...config,
          formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
        } as Html5QrcodeFullConfig)
      }

      const html5QrcodeScanner = html5QrcodeScannerRef.current

      html5QrcodeScanner
        .start(
          {
            aspectRatio: 1,
            facingMode: 'environment',
            frameRate: { ideal: 10, max: 15 },
            noiseSuppression: true,
            width: { min: 300, ideal: 420 },
            height: { min: 300, ideal: 420 },
          },
          {
            fps: 10,
            qrbox: 300,
            disableFlip: true,
            videoConstraints: {
              facingMode: 'environment',
              width: { min: 300, ideal: 420 },
              height: { min: 300, ideal: 420 },
            },
          },
          async (text, result) => {
            console.log('actions', actions)
            console.log('text', text)
            if (actions.length > 0) return

            if (onSuccess) {
              onSuccess(text, result)
            }

            requestSignature(text, { account, pubKey })
            console.log('requested...')
            html5QrcodeScanner?.pause()
          },
          (errorMessage, error) => {
            if (!error.type) return
            console.error('Error scanning QR code: ', errorMessage, error)
            onError && onError(errorMessage, error)

            cleanQRScanner()
          },
        )
        .catch((error) => {
          console.error('Failed to start html5QrcodeScanner. ', error)
          cleanQRScanner()
        })
      clearTimeout(timeout)
    }, 1000)

    // cleanup function when component will unmount
    return () => {
      cleanQRScanner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pubKey])

  // if (actions.length > 0)
  //   return <RawDataObjectContainer>{JSON.stringify(actions, null, 2)}</RawDataObjectContainer>

  return <QRCodeScannerContainer id="qrcode-scanner" />
}

// { height: 400, maxHeight: 400, width: 400, maxWidth: 400, borderRadius: 10, background: '#fafafa', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }
const QRCodeScannerContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 325px;
  height: 100%;
  max-height: 390px;
  overflow: hidden;
  border: 4px solid #eaeaea;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button#html5-qrcode-button-camera-permission {
    border-radius: 24px;
    line-height: 1.25;
    font-size: 1.25rem;
    width: 100%;
    max-width: 220px;
    padding: 6px 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0;
    transition: 0.24s all ease-in-out;
    background: ${({ theme }) => theme.colors.black};
    color: #ffffff;
    margin-inline: auto;
    cursor: pointer;

    :hover,
    :focus-within {
      transform: scale(1.05);
    }
  }

  div#qr-shaded-region,
  div#qrcode-scanner__scan_region {
    height: 100%;
    max-height: 390px;
  }

  div#qrcode-scanner__dashboard {
    height: 0px;
  }
`

export const RawDataObjectContainer = styled.code`
  height: 400px;
  max-height: 400px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  display: flex;
  align-items: start;
  padding: 16px;
  justify-content: start;
  flex-direction: column;
  white-space: pre-wrap;
  font-family: monospace;
  margin-bottom: 24px;
  overflow-y: auto;
  font-size: 0.92rem;
  border: 3px solid #eaeaea;

  ${scrollbar_css}

  overflow-x: auto;
`

interface QrScanProps extends Omit<Html5QrcodeConfigs, 'fps'> {
  onSuccess?: QrcodeSuccessCallback
  onError?: QrcodeErrorCallback | undefined
}
